@use "sass:color"

@use "bootstrap/mixins"

// CVP Colors
$colorInk: #0A1D32
$colorSky: #4363FF
$colorAvocado: #8ae133
$colorWhite: #FFFFFF

// Graytones
$colorGray1: #E0DDDA
$colorGray2: #E7E5E1
$colorGray3: #EFEDEA
$colorPaper: #F5F4F2
$colorGray4: #F8F7F7
$colorGray5: #F9F9F9
$colorGray6: #FBFBFB

// Sprites
$sprite_width: 200px 1100px
$sprite_width_third: 150px 824px
$sprite_width_half: 100px 550px

// To override input fields invalid
.invalid_wrapper:after
  content: '!'
  position: absolute
  right: 100%
  top: 0
  padding-top: 3px
  margin-right: 2px
  width: 10px
  height: 100%
  text-align: center
  font-weight: bold
  font-size: 20px
  background-color: #FFF
  color: #fbc2c4
  z-index: 10
  box-sizing: border-box
// end

// Dropdown list general
// TODO: Remove
.list_dropdown
  position: fixed
  z-index: 1051
  top: -100px
  left: 0px
  font-size: 12px
  opacity: 0
  max-width: 10%
  min-width: 95px
  background-color: #FFFFFF
  border: 1px solid #d5d5d5

  &.show_list_dropdown
    opacity: 1
    top: 40px

  .list_dropdown_container
    padding-top: 10px
    padding-bottom: 5px

    .head
      padding: 10px 10px 5px
      font-size: 14px

    .list
      font-size: 12px
      overflow: hidden
      overflow-y: auto
      max-height: 300px

      li
        padding: 5px 10px
        color: $colorSky
// end list dropdown


// Fixed translation menu
.fixed_menu
  position: fixed
  left: 8px
  top: 59px
  z-index: 110
  height: 0
  &.absolute
    position: absolute
    top: 49px
    left: 8px
    height: auto

  &.with_offset
    margin-top: 75px

  .fixed_menu_element
    background-color: #FFF
    margin-top: 10px
    font-size: 12px
    cursor: pointer
    border-radius: 2px
    box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05), 0 12px 96px rgba(0,0,0,0.1), 0 12px 36px rgba(0,0,0,0.05)
    &.with_icon
      &:after
        content: ''
        height: 25px
        width: 25px
        background: url("../images/icons/new_cv_sprites_x2.png")
        background-size: $sprite_width_half
        background-repeat: no-repeat
        position: absolute
        right: 5px
        top: 5px
      &.download_icon
        &:after
          background-position: -25px -150px
        &:hover:after
          background-position: -50px -150px
      &.read_only_icon
        &:after
          background-position: -25px -400px
        &:hover:after
          background-position: -50px -400px
      &.edit_icon
        &:after
          background-position: -25px -50px
        &:hover:after
          background-position: -50px -50px

  .toggle_action
    padding: 10px
    padding-right: 40px
    display: block
    position: relative
    font-weight: bold
    text-transform: uppercase

  // toggle international
  .toggle_international
    overflow: hidden
    max-height: 70px
    max-width: 165px
    position: fixed
    top: 50px
    z-index: 120

    .section_heading
      padding: 10px 30px 10px 10px
      font-weight: bold
      font-size: 12px
      margin-bottom: 10px
      text-transform: uppercase

      &.dropdown_heading
        margin: 12px 0 8px

    &.show_options
      max-height: 300px
      padding: 10px
      overflow: visible
      border-bottom-right-radius: 0px
      cursor: default
      .section_heading
        padding: 0
      &.with_icon:after
        display: none
      .close_translation_options
        display: block

    .currently_showing
      font-weight: normal
      display: block
      text-transform: none
      &.below_dropdown
        margin-top: 10px

    .close_translation_options
      display: none
      position: absolute
      top: 0
      right: 0
      width: 20px
      height: 20px
      cursor: pointer
      background: url("../images/icons/new_cv_sprites_x2.png")
      background-size: $sprite_width_half
      background-repeat: no-repeat
      background-position: -27px -77px
      background-color: #f8f8f8
      border-bottom: 1px solid #EEE
      border-left: 1px solid #EEE

    .divider
      margin: 12px 25px 12px
      height: 1px
      background-color: #E8E8E8


// Collapseable buttons on expanded sections
.minimizer
  position: absolute
  top: 0px
  left: 0px
  width: 100%
  color: $colorSky
  background-color: #FBFBFB
  height: 20px
  line-height: 20px
  text-align: center
  border-bottom: 1px solid #EFEDEA
  cursor: pointer
  padding: 7px 0
  line-height: 22px
  font-size: 12px
  letter-spacing: 1px
  border-top-left-radius: 5px

  &.horizontal
    top: 0px
    left: 100%
    margin-left: -16px
    background-color: #f8f8f8
    width: 15px
    height: calc(100% - 1px)
    border-left: #f1f1f1 1px solid
    border-bottom: 0
    padding: 0

    &:focus
      @include mixins.darken(#f8f8f8)

      &:before
        @include mixins.darken(#f8f8f8)

    &:before
      content: ''
      top: 0
      left: -1px
      width: 1px
      background-color: #f8f8f8
      height: 30px
      position: absolute

  &:focus
    @include mixins.darken(#f8f8f8)

  &:focus + .horizontal, &:focus + .horizontal:before
    @include mixins.darken(#f8f8f8)

.toLevelList
  > li
    margin: 10px 0
    padding: 0
    position: relative

.topLevelList, .competence_container
  position: relative

  .li_wrapper
    box-shadow: 0px 0px 0px 1px rgba(224, 224, 224, 0.75)
    border-bottom-left-radius: 10px
    border-top-left-radius: 10px

  .release_here_to_move
    position: absolute
    top: 10px
    right: 200px
    color: gray
    font-style: italic

  &.dragging_item
    .ui-sortable-handle
      > .handle
        display: none

  .draggable_placeholder
    position: relative
    height: 50px
    width: 100%
    margin: 5px 0
    border-top-left-radius: 10px
    border-bottom-left-radius: 10px
    background: #DDD
    color: #DDD

    &:before
      content: ''
      position: absolute
      left: -35px
      top: 0
      height: 100%
      width: 30px
      background: url("../images/icons/mover_right.png") no-repeat center center

  > li
    margin: 5px 0
    padding: 0
    position: relative
    transition: opacity 0.2s ease-in
    &.expandedWrapper
      animation: animAccordion 0.1s cubic-bezier(0.7,0,0.3,1) forwards

    &.dragging
      .expandSection, .handle
        cursor: url("../images/cursors/closedhand.cur") 7 5, default

  > li:only-of-type
    .radiobutton
      display: none

.contextual_wrapper
  .block_section.custom_container
    .vertical_plus, .horizontal_plus
      background-color: $colorInk

.subsection_switch_wrapper
  position: absolute
  top: 0
  right: 0
  border-bottom: 1px solid $colorPaper
  border-bottom-left-radius: 10px
  .switch_container_collapsed
    border-bottom-left-radius: 10px
    border-top: 0
  .removeSection
    border-top: 0

// Secondary container
.switch_container_collapsed
  display: table-cell
  vertical-align: middle
  padding: 8px
  box-sizing: border-box
  background-color: $colorGray5
  border-left: 1px solid $colorPaper
  font-size: 14px

  .highlighted_star
    width: 25px
    height: 25px
    background: url("../images/icons/new_cv_sprites_x2.png")
    background-size: $sprite_width_third
    background-repeat: no-repeat
    background-position: -5px -156px

    &.starred
      background-position: -80px -156px

.removeSection
  width: 50px
  display: table-cell
  vertical-align: middle
  border-left: 1px solid $colorPaper
  box-sizing: border-box
  background-color: $colorGray5

  span.remove
    background: url("../images/icons/new_cv_sprites_x2.png")
    background-size: $sprite_width
    background-repeat: no-repeat
    background-position: 0px -150px
    width: 50px
    height: 50px
    display: inline-block
    vertical-align: middle

  &:hover, &:focus
    visibility: visible
    .remove
      background-position: -100px -150px
    .approve
      background-position: -105px -33px

.confirm_delete_wrapper, .cancel_delete_wrapper
  position: absolute
  display: inline-block
  top: 0px
  right: 0px
  height: 100%
  background-color: #DFDFDF
  width: 140px
  text-align: center
  z-index: 200
  color: #555
  box-sizing: border-box
  @include mixins.color_style(#f4f4f4)

  .confirm_delete, .cancel_delete
    position: absolute
    left: 0px
    top: 50%
    margin-top: -12px
    width: 100%

  .center_helper
    display: inline-block
    vertical-align: middle
    height: 100%

.confirm_delete_wrapper
  right: 140px !important
  background-color: #bb1212
  color: #FFF
  width: 200px
  &:hover
    background-color: color.adjust(#bb1212, $lightness: -10%)

.expandSection
  display: table-cell
  width: 100%
  vertical-align: middle
  position: relative
  padding: 0 70px 0 30px
  background-color: #FFF
  border-top-left-radius: 10px
  border-bottom-left-radius: 10px
  box-sizing: border-box
  min-height: 95px
  border-right: 0px

  .pencil
    position: absolute
    right: 0px
    top: 50%
    width: 50px
    height: 42px
    margin-top: -21px
    display: none
    background: url("../images/icons/new_cv_sprites_x2.png")
    background-size: $sprite_width
    background-repeat: no-repeat
    background-position: -100px -104px
    background-color: #f8f8f8

  &:hover
    background-color: #fafafa
    .pencil
      display: block
      background-color: #fafafa

  .content_indicator_wrapper
    position: absolute
    height: 100%
    right: 50px
    top: 0px
    border-bottom: 1px solid #D0D0D0
    display: flex
    vertical-align: middle
    align-items: center
    justify-content: flex-end
    gap: 10px
    .content_indicator_container
      .content_number
        font-size: 10px
        text-align: center
      .image_icon
        background-image: url("../images/icons/icon_sprite_x2.png")
        background-repeat: no-repeat
        background-size: 200px
        background-position: -61px -94px
        width: 28px
        height: 21px
      .attachment_icon
        background-image: url("../images/icons/icon_sprite_x2.png")
        background-repeat: no-repeat
        background-size: 200px
        background-position: -59px -147px
        width: 28px
        height: 32px

.ui-sortable-handle
  position: relative


// Icons
.star, .comment, .merge_with_master
  background: url("../images/icons/new_cv_sprites_x2.png")
  background-size: $sprite_width
  background-repeat: no-repeat
  height: 50px
  width: 50px
  position: absolute
  top: 50%
  margin-top: -25px
  cursor: pointer

.star
  display: none

.star.expanded_star
  display: block
  background-position: 0px -200px
  right: -60px
  margin-top: 3px
  top: 0px

  &:hover
    background-position-x: -50px

  &.starred
    background-position: -100px -200px
    .tooltip
      display: none

  &.read_only_star
    margin-top: 0px
    top: -20px
    right: -70px

// Merge with master icon
.merge_with_master
  background-position: 0px -450px
  right: -110px

  &.expanded_merge_with_master
    right: -60px
    top: 128px

  &:hover
    background-position: -50px -450px

  &.disabled
    background-position: -100px -450px

  &.activated
    background-color: #FFF
    border-top-right-radius: 3px
    border-bottom-right-radius: 3px
    box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05), 0 12px 96px rgba(0,0,0,0.1), 0 12px 36px rgba(0,0,0,0.05)

    .tooltip
      display: none

  .context_action_container
    position: absolute
    right: 100%
    padding: 5px 10px
    border-top-left-radius: 3px
    border-bottom-left-radius: 3px
    border-bottom-right-radius: 3px
    background-color: #FFF
    box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05), 0 12px 96px rgba(0,0,0,0.1), 0 12px 36px rgba(0,0,0,0.05)
    z-index: 200
    width: 200px

// comment icon
.comment
  background-position: 0px -950px
  right: -60px

  &.expanded_comment
    right: -60px
    top: 78px

  &.active
    // Color set in colorbox mixin

  &.commented
    background-position: -100px -950px

  &:hover
    background-position: -50px -950px

  &.commented, &.commented:hover
    background-position: -100px -950px


.disableExpandsection
  &:hover, &:focus
    &:before
      background-color: #999999 !important
  .expandCollection
    opacity: 0.75

// Fields for collapsed sections
.collapsedField
  overflow: hidden
  text-overflow: ellipsis
  white-space: normal
  display: block
  max-width: 100%
  max-height: 100px
  margin: 5px 5px 0 0
  color: $colorInk
  font-size: 0.9em

  &.tag
    font-weight: bold
    padding: 5px 10px
    background-color: #F5F5F5
    border-radius: 5px

.dateCollection, .dataCollection
  display: inline-block
  vertical-align: middle
  padding: 10px 0 15px 0

  .emphasize
    font-weight: bold
  .meta_field
    opacity: 0.5
    margin-left: 5px

.dateCollection
  width: 90px
  .date
    opacity: 0.5
  .date_expired
    display: inline-block
    font-size: 12px
    text-align: left
    padding: 4px
    background: #ffd9d9
    border-radius: 2px
    color: #6d0000
    margin-top: 5px
    margin-right: 10px
  .date_arrow
    display: block
    height: 5px

.dataCollection
  width: 70%

.new_section
  position: absolute
  right: 0px
  top: 0px
  height: calc(100% - 20px)
  padding: 10px
  margin: 10px
  color: $colorInk
  background-color: $colorAvocado
  border-bottom: 1px solid color.adjust($colorAvocado, $lightness: -10%)
  border-radius: 5px
  box-sizing: border-box
  z-index: 10

.dataCollection.singelCollection
  width: 100%

.extra_min_width
  min-width: 270px

.subsection_point_wrapper
  margin-bottom: -10px
  margin-top: 10px

.subsection_point
  padding: 5px 8px 5px
  border: 1px solid #EEE
  display: inline-block
  border-radius: 3px
  margin: 0 5px 5px 0
  font-size: 11px

// Main cointainer to fix the scrollbar position
.main_container
  padding-bottom: 10%
  background-color: $colorPaper
  width: auto
  z-index: 2
  position: fixed
  top: 50px
  left: 0
  right: 220px
  bottom: 0
  overflow-y: scroll

// Sidebar

// sidebar general
#main_container
  .scroll_nav
    background-color: #FFFFFF
    width: 220px
    min-height: 100%
    position: fixed
    top: 0
    right: 0
    bottom: 0
    z-index: 1
    overflow-y: auto
    overflow-x: hidden
    margin-top: 25px
    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 7px
      background-color: #f0f0f0

    #cv_metadata_wrapper
      padding-top: 60px
      padding-bottom: 10px
      margin-left: 7px
      background-color: #FFF

    .last_edit
      color: $colorSky
      font-size: 12px

      .dot_one, .dot_two, .dot_three
        animation: dot1 1s infinite steps(1)

      .dot_two
        animation-name: dot2

      .dot_three
        animation-name: dot3

    li
      position: relative
      z-index: 1
      padding: 4px 0 0 0
      margin-left: 7px

      .draggable_placeholder
        padding: 10px 10px 5px 0
        margin: 10px 10px 0 20px
        background-color: #f8f8f8
        border: 2px dotted #E4E4E4
        border-bottom: 0
        text-align: center
        text-transform: uppercase
        font-size: 12px
        border-radius: 4px

        &+ a
          border: 2px dotted #E4E4E4
          margin: 0 10px 0px 20px
          border-top: 0
          background-color: #f8f8f8
          text-align: center
          margin-bottom: 0px

      a
        padding: 12px 0 15px 15px
        display: block
        font-size: 14px
        line-height: 16px
        margin-left: 10px

        &:focus
          text-decoration: underline

        .colorblock
          position: absolute
          left: 0
          top: 0
          width: 1em
          height: 100%
          display: block

          box-shadow: inset 1px 0px 1px 0px rgba(88, 88, 88, 0.24)
// end

// Dragging technologies
#technologies
  .competence_wrapper.ui-state-hover
    background: #f8f8f8
  &.dragging_container_item
    .ui-sortable-handle
      > .handle
        display: none
  &.dragging_item
    .competence_wrapper
      height: 120px
      overflow: hidden
      background: transparent
      &:after
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        border: 2px dashed #a8bac2
        z-index: -1
        border-radius: 5px

      &.ui-state-hover
        background: #FFFFFF

      .skill_tag
        display: none

      .button.full_width
        visibility: hidden

      .input_toggle_translation
        display: none

      .switch_container_collapsed
        visibility: hidden

      .cv_input
        border-color: transparent

    .empty_competence
      display: block
      .competence_cell
        display: block
        width: auto

    .ignore_dragging_on_self
      background: #FFF
      height: auto
      overflow: visible
      opacity: 1
      z-index: 10

      .release_here_to_move
        display: none

      .skill_tag
        display: inline-block

    .dragging_above_item
      height: auto
      .skill_tag .skill_name
        background-color: #FFF

      &.ui-state-hover
        opacity: 1

      .skill_tag
        display: inline-block

// Translation input dropdown
.expand_international
  .block_section_wrapper .block_section.full_width.expand_international_wrapper
    width: calc(50% - 60px)
    display: inline-block
  .block_section_wrapper .block_section.full_width.hide_international_wrapper
    width: 50%
    display: inline-block

  .block_section_wrapper
    .empty_international
      .block_section.full_width.expand_international_wrapper
        width: calc(100% - 60px - 250px)

      .block_section.full_width.hide_international_wrapper
        width: 250px
      .block_section.full_width.hide_international_wrapper .saved_message
        visibility: hidden

  .international_input_wrapper
    position: absolute
    top: 38px
    left: -14px
    min-width: 300px
    padding: 5px 15px 15px 15px
    z-index: 10
    background-color: #F8f8f8
    border: 1px solid #EEE
    border-width: 0px 1px 1px 0px
    box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05), 0 12px 96px rgba(0,0,0,0.1), 0 12px 36px rgba(0,0,0,0.05)

    .international_input .autosuggest
      top: 79px


.hide_international
  .block_section_wrapper .block_section.full_width.hide_international_wrapper
    display: none

  .int_cv_input, .int_cv_textarea_wrapper, .int_state_container, .input_internal_dropdown
    display: none !important
    // warn important
  .skill_translations
    display: none

// Draggable / orderable containers
.orderable_container
  position: relative

  &.dragging_active
    .handle, .checkmark_option
      transition: all 0.2s
      opacity: 0

  .ui-sortable-handle

    > .handle
      margin-top: 0px
      left: 10px
      top: 10px

      &:hover
        .mover
          display: block

    > .handle.small_grid
      height: 19px
      width: 19px
      left: 7px

  .draggable_placeholder
    position: relative
    height: 35px
    margin: 0 35px 0 0
    border: 2px dotted #CCC
    background: #f7f7f7
    box-sizing: border-box

    &:before
      content: ''
      position: absolute
      left: -35px
      top: 0
      height: 100%
      width: 30px
      background: url("../images/icons/mover_right.png") no-repeat center center

    .above, .below
      display: none

.temp_add_skill_input
  width: 250px

@media only screen and (max-device-width: 1100px), screen and (max-width: 1100px)
  .main_container
    right: 0

@media only screen and (max-device-width: 500px), screen and (max-width: 500px)
  .dateCollection
    padding-bottom: 0

  .orderable_container
    .handle
      display: none !important

  .temp_add_skill_input
    width: 200px
