@use "sass:color";

// Mixins.scss
// Snippets of reusable CSS to develop faster and keep code readable
// -----------------------------------------------------------------

// UTILITY MIXINS
// --------------------------------------------------

// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
@mixin clearfix() {
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

// New image replacement
// -------------------------
// Source: http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
@mixin hide-text() {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

// FORMS
// --------------------------------------------------

// Block level inputs
@mixin input-block-level() {
  display: block;
  width: 100%;
  min-height: 28px; /* Make inputs at least the height of their button counterpart */
  /* Makes inputs behave like true block-level elements */
  box-sizing: border-box;
}

// CSS3 PROPERTIES
// --------------------------------------------------

// Interactive element
@mixin interactive {
  user-select: none;
  cursor: pointer !important;
}

// COLOR_MAPPER
// --------------------------------------------------

@mixin set_search_color($color) {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
  padding-left: 22px !important;
  position: relative !important;
  &:after{
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    width: 10px;
    height: calc(100% + 2px);
    background-color: $color;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

// Add color to box
@mixin boxcolor($color, $nextcolor: #E8E8E8) {

    &:before {
      background-color: $color;
    }

    .subsection_wrapper{
      &:before {
        border: 2px solid $color;
      }

      &:after {
        background-color: $color;
      }
    }

    .leftdivider, .rightdivider {
      border-bottom: solid 1px $color;
    }

    h2 {
      border-color: $color;
      position: relative;
    }

    .topLevelList > li:before {
      border-color: $color;
      background-color: $color;
    }

    .custom_container.is_alone {
      border-right: 10px solid $color !important;
    }

    .choice {
      border-color: transparent transparent $color #DADADA;
    }

    .topLevelList {
      border-right: solid $color 10px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .block_section_container {
      border-right: solid $color 10px;
    }

    .expandSection:hover, .expandSection:focus {
      &:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 5px;
        background-color: $color;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      .handle .grid{
        background-color: color.adjust(#DDD, $lightness: -10%)
      }
    }

    .missing {
      .expandSection:hover {
        background-color: color.adjust($color, $lightness: 20%)
      }
    }

    .skewbottom {
      background-color: color.adjust($color, $lightness: -20%);
    }

    .skewright {
      background-color: color.adjust($color, $lightness: -10%);
    }

    .contextual_element {
      border-right: 5px solid $color;
    }

    span.tag {
      background-color: $color;
    }

    span.tag a {
      background-color: color.adjust($color, $lightness: -20%)
    }

    span.tag a:hover {
      background-color: color.adjust($color, $lightness: -30%)
    }

    .more_suggestions {
      background-color: #CCC
    }

    .more_suggestions:hover, .more_suggestions:focus {
      background-color: color.adjust(#CCC, $lightness: 5%);
    }

    .skill_read_only {
      border-left-color: $color;
    }
}

// Add color to sidenavbar
@mixin sidenavbar($color) {
  .colorblock {
    background-color: $color;
  }
}

@mixin sidenavbar_hover($color) {
  background: #E8E8E8;
  margin-left: 0;
  padding-top: 0;
  box-shadow: 6px -1px 5px 0px rgba(175, 175, 175, 0);
  border-top: #f0f0f0 4px solid;
  z-index: 200;
  outline: none;

  .colorblock {
    border-top: 3px solid color.adjust($color, $lightness: 10%);
    top: -3px;
    box-shadow: inset 1px 0px 1px 0px rgba(88, 88, 88, 0);
  }
  .badge_override {
    margin-right: 17px !important;
  }
}

@mixin darken($color, $percent: 5%) {
  background-color: color.adjust($color, $lightness: -$percent)
}

@mixin top_divider() {
  border-top: 1px solid #bdbdbd;
  box-shadow: inset 0px 1px #FFF;
}

// 3d Buttons
// --------------------------------------------------

@mixin color_style($color, $border: 0) {
  background-color: $color;
  border: $border solid color.adjust($color, $lightness: -10%);
  &:hover {
    background-color: color.adjust($color, $lightness: -5%);
    border: $border solid color.adjust($color, $lightness: -15%);
  }
  &:focus {
    background-color: color.adjust($color, $lightness: -10%);
    border: $border solid color.adjust($color, $lightness: -15%);
  }
}

// CRUNCHYBUTTONS SHADOW
// --------------------------------------------------

@mixin crunchybutton() {
  box-shadow: inset 0 -2px rgba(255, 255, 255, 0.1), inset 0 -4px rgba(0, 0, 0, 0.1), 0 1px rgba(0, 0, 0, 0.05);
  font-family: 'Century Gothic', CenturyGothic, sans-serif;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
}

@mixin crunchybutton_shadow() {
  box-shadow: inset 0 -2px rgba(255, 255, 255, 0.1), inset 0 -4px rgba(0, 0, 0, 0.1), 0 1px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.2);
  font-family: 'Century Gothic', CenturyGothic, sans-serif;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
}

@mixin crunchybutton_shadow_notop() {
  box-shadow: inset 0 -2px rgba(255, 255, 255, 0.1), inset 0 -4px rgba(0, 0, 0, 0.1), 0 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.2);
}

@mixin crunchybutton_active() {
  box-shadow: inset 0 -1px rgba(0,0,0,0.1),0 1px rgba(0,0,0,0.05), 0px 2px 3px rgba(0, 0, 0, 0.3);
}

@mixin crunchybutton_reset() {
  box-shadow: inset 0 0px rgba(0,0,0,0.0);
}

@mixin crunchyelement() {
  box-shadow: inset 0 1px #FFF, inset 0 0 0 1px rgba(255, 255, 255, 0.7), 0 2px #DADADA, 0 3px #C4C4C4, 0 4px 3px rgba(0, 0, 0, 0.1);
}

// Mixins Output
// -------------
.clearfix { @include clearfix(); }
.hide-text { @include hide-text(); }
.input-block-level { @include input-block-level(); }
