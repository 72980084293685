// CVP Colors
$colorInk: #0A1D32
$colorSky: #4363FF
$colorAvocado: #8ae133
$colorWhite: #FFFFFF

.main_container.read_only
  top: 0

  .muted_gray
    opacity: 0.5
    font-size: 20px
    display: block

  .sectionSupport, .addSection, .leftdivider, .rightdivider
    display: none

  h2
    letter-spacing: 0.02rem
    font-size: 26px
    font-weight: bold
    margin-bottom: 10px
    white-space: initial

    &:before
      display: none

  label
    margin: 0

  fieldset
    padding: 0

  .long_description
    line-height: 140%
    letter-spacing: 0.03rem

  .customer_description_wrapper, .long_description_wrapper, .customer_value_proposition_wrapper
    padding: 0
    margin-bottom: 10px

  .roles_wrapper, .key_points_wrapper
    margin: 20px 0

  .subsection_wrapper
    padding-left: 40px
    position: relative
    margin-bottom: 10px
    &.disabled_section
      color: #BBB
      .read_only_section_help
        font-size: 10px
        text-transform: uppercase
        font-weight: bold
        margin-left: 10px
      &:after
        background-color: #BBB !important
      &:before
        border-color: #BBB !important

    &:before
      content: ''
      position: absolute
      left: 15px
      top: 4px
      width: 8px
      height: 8px
      border-radius: 50%
      background-color: #f8f8f8
      z-index: 2
      border: 2px solid $colorAvocado
    &:after
      content: ''
      position: absolute
      left: 20px
      top: 4px
      width: 2px
      height: 100%
      border-radius: 50%
      z-index: 1
      background-color: $colorAvocado

  .subsection_name
    font-weight: bold
    margin-bottom: 0

  .fieldsetWrapper
    padding-top: 0px
    margin-top: 100px
    margin-left: 15%
    margin-right: 10%
    margin-bottom: 50px
    position: relative
    &:before
      content: ''
      width: 4px
      display: block
      height: 100%
      position: absolute
      left: -30px
      top: 0px

  .expandedWrapper
    padding: 0
    background-color: transparent
    border: 0
    animation: reset_animation

  .topLevelList
    box-shadow: 0 0 0 rgba(0,0,0,0), inset 0 0 0 rgba(0,0,0,0)
    border: 0 !important
    margin-top: 0
    background-color: transparent

    > li
      border: 0
      background-color: $colorWhite
      margin-bottom: 50px
      padding: 20px
      padding-bottom: 10px
      box-sizing: border-box
      border-radius: 5px

      &:before
        content: ''
        position: absolute
        left: -35px
        top: 24px
        border: 2px solid $colorAvocado
        width: 10px
        height: 10px
        border-radius: 50%
        box-shadow: inset 0px 0px 10px #FFF
        background-color: $colorAvocado

      &.disabled_section
        .read_only_section_help
          position: absolute
          top: -20px
          right: 0px
          font-size: 10px
          text-transform: uppercase
          font-weight: bold
          opacity: 0.7
        .expandedWrapper
          max-height: 100px
          overflow: hidden
          cursor: pointer
          color: #BBB
          .meta_field
            opacity: 0.7
          &:before
            content: ""
            position: absolute
            width: 100%
            height: 25px
            bottom: 0
            left: 0
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(248,248,248,1) 70%)
            z-index: 1
          &.show_full_section
            max-height: none
            overflow: initial
            color: #444
            + .read_only_section_help
              color: #757575
            .meta_field
              opacity: 0.7
            &:before
              display: none

  .basicInfo
    font-weight: bold
    margin-bottom: 10px
    margin-right: 0

    .primary_field
      width: 100%
      font-size: 20px
      word-break: break-all

    .fieldWrapper.secondary_field
      margin-right: 5px

    .meta_field
      opacity: 0.7
      margin-right: 5px

  .dateCollector
    text-align: right
    position: absolute
    left: -120px
    top: 9px
    color: $colorInk
    opacity: 0.7
    font-size: 12px
    text-transform: uppercase
    width: 70px
    height: 20px

  .from_dates, .to_dates
    width: 100%

  .fieldWrapper
    display: inline-block

  .fieldWrapper, .monthWrapper, .yearWrapper
    margin: 0
    min-width: 30px

  .month_input
    padding-right: 5px

  #history
    position: relative
    padding: 50px 0
    margin: 45px 0
    h2
      text-align: center

  #personal_details
    margin: 100px 10% 0 15%
    padding: 0
    background-color: transparent
    min-height: 215px
    position: relative
    opacity: 1

    .preload
      box-shadow: 0 0 0 rgba(0,0,0,0), inset 0 0 0 rgba(0,0,0,0)
      overflow: hidden

    #detailsAndImageWrapper
      padding: 0
      text-align: left
      background-color: $colorWhite
      padding: 20px 20px 0 20px

    #image
      background-color: $colorWhite

    .skill_tag.custom_tag
      .skill_name
        background-color: $colorWhite
        border-radius: 5px
        border: 0
        cursor: default
        padding: 7px 20px 8px 35px
        letter-spacing: 0.01rem

    #details
      background-color: $colorWhite
      max-width: none
      width: 100%
      box-sizing: border-box

      .image_wrapper
        width: 200px
        height: 200px
        background: #f6f7f8
        background: linear-gradient(to right, #eeeeee 8%, #E0E0E0 18%, #eeeeee 33%)
        background-size: 600px 200px
        &.preload_image
          animation-duration: 1.2s
          animation-fill-mode: forwards
          animation-iteration-count: infinite
          animation-name: placeHolderPreLoader_image
          animation-timing-function: linear

      .preload
        margin-top: 20px
        .preload_mask
          height: 20px
          display: block
          &.half_mask
            display: inline-block
            width: 50%
          &:before
            border-width: 0 500px 20px 20px
      ul
        max-width: 960px
        margin: 0 auto
        &.preload
          animation-duration: 1.2s
          animation-fill-mode: forwards
          animation-iteration-count: infinite
          animation-name: placeHolderPreLoader_input
          animation-timing-function: linear
          background: #f6f7f8
          background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)
          background-size: 2880px 100%
          li
            margin: 0 20px 20px 20px
            transition: all 0s
        .preload_mask
          position: relative
          &:before
            content: ''
            position: absolute
            width: 100%
            height: 100%
            margin-left: -20px
            border-width: 0 20px 20px 20px
            border-style: solid
            border-color: #FFFFFF

      fieldset
        text-align: left

        .readonly_image, .nopicture_placeholder
          display: block
          float: left
          width: 175px
          height: 175px
          line-height: 162px
          margin-bottom: 20px
          font-size: 84px
          border-radius: 50%

        ul
          margin-left: 175px
          label
            font-size: 10px
            padding-bottom: 0
            position: relative
            top: -2px
            letter-spacing: 0.04rem
            left: 0
            display: block
            text-transform: uppercase
            font-weight: bold
          li
            width: auto
            min-width: 100px
            min-height: 0
            margin: 0 20px 20px 20px
            display: inline-block
            vertical-align: top
            text-align: left
            &.name, &.title
              margin-bottom: 0px
              display: block
              h2
                margin: 0
            &.title
              h2
                font-weight: normal
                opacity: 0.7
                margin-bottom: 20px


  #project_experiences
    .topLevelList > li
      padding-bottom: 0

  #technologies .show_experience, #technologies .hide_experience
    display: none

  #technologies .competence_container#technologies_list
    .competence_wrapper
      border: 0
      display: block
      padding: 10px 20px 0px 20px
      margin: 30px 0
      vertical-align: top
      background-color: #FFF
      box-shadow: 0 0 0 rgba(0,0,0,0), inset 0 0 0 rgba(0,0,0,0)
      box-sizing: border-box
      border-radius: 5px
      position: relative

      &.disabled_competence_wrapper
        opacity: 0.5
        .read_only_section_help
          position: absolute
          top: -20px
          right: 0px
          font-size: 10px
          text-transform: uppercase
          font-weight: bold

      .category
        font-weight: bold
        margin-bottom: 10px

      .skills_wrapper
        padding: 0
        min-height: 0

  #languages #languages_list
    fieldset
      min-height: 0
    .basicInfo
      padding-top: 0

@media only screen and (max-device-width: 1100px), screen and (max-width: 1100px)
  .main_container.read_only

    .read_only_hider, .read_only_user_container, .fixed_menu
      display: none

    .fieldsetWrapper, #personal_details.fieldsetWrapper
      margin: 100px 10px 20px 10px !important
      padding-left: 0px

      &:before
        left: 0
        z-index: 10

    #personal_details.fieldsetWrapper
      margin-bottom: 50px

    h2
      margin-left: 20px

    .basicInfo .dateCollector
      position: static
      left: 0
      top: 0
      width: 100%
      text-align: left
      display: block
      height: auto

      .from_dates, .to_dates
        width: auto

      .monthWrapper, .yearWrapper
        min-width: 0
        text-align: left

      .year_from
        margin-right: 8px
        padding-right: 4px
        &:before
          content: '-'
          position: absolute
          width: 10px
          left: 100%
          top: 0

@media only screen and (max-device-width: 750px), screen and (max-width: 750px)
  .main_container.read_only
    #technologies .competence_container#technologies_list .competence_wrapper
      width: 100%
      margin-right: 0
      &:nth-of-type(odd)
        width: 100%
        margin-right: 0

@media only screen and (max-device-width: 500px), screen and (max-width: 500px)

  .main_container.read_only
    #personal_details #details fieldset
      ul
        margin-left: 0px
        float: left
        width: 100%

        li
          margin: 0 20px 20px 0

    #languages
      margin-bottom: 150px
  // Remains hack
  .main_container
    .fieldsetWrapper
      height: auto
      overflow: hidden
