/*
 *    ColorBox Core Style:
 *    The following CSS is consistent between example themes and should not be altered.
 */
#colorbox, #cboxOverlay, #cboxWrapper
  position: absolute
  top: 0
  left: 0
  z-index: 99999
  overflow: hidden

#colorbox
  margin: 0.5em
  background-color: #FFF
  border-radius: 8px

#cboxClose
  position: absolute
  top: 0
  right: 0
  width: 200px
  height: 50px
  padding: 0
  text-indent: -9999px

#cboxOverlay
  position: fixed
  width: 100%
  height: 100%
  background-color: grey

#cboxMiddleLeft, #cboxBottomLeft
  clear: left

#cboxContent
  position: relative

#cboxLoadedContent
  overflow: auto

#cboxTitle
  margin: 0

#cboxLoadingOverlay, #cboxLoadingGraphic
  position: absolute
  top: 0
  left: 0
  width: 100%

#cboxLoadingGraphic
  background: url("@cvpartner/design-system/logos/royalBlueIcon.svg") no-repeat
  background-size: 100%
  width: 100px
  height: 130px !important
  position: fixed
  top: 50%
  left: 50%
  margin-left: -50px
  margin-top: -100px
  z-index: 10000
  float: none !important
  opacity: 0
  animation: animate_in_opacity 0.7s ease forwards

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow
  cursor: pointer

.cboxPhoto
  float: left
  margin: auto
  border: 0
  display: block

.cboxIframe
  width: 100%
  height: 100%
  display: block
  border: 0

@-webkit-keyframes animate_in_opacity
  from
    opacity: 0
  to
    opacity: 1
